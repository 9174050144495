import React, { useEffect } from 'react';
import { MessagingState } from '../context/Context';
import { useTranslation } from 'react-i18next';
import { ActionType } from '../context/ActionTypes';
import DiscussionThreadListItem from './DiscussionThreadListItem';
import classNames from 'classnames';
import { ISearchResult } from '../@types/search';
import FolderService, { FolderSelection } from '../services/FolderService';
import Paginate from './Paginate';
import { Spinner, Icon } from '@vismaux/react-vud';
import { LoadingState } from '../@types/loadingstates';
import DiscussionThreadService from '../services/DiscussionThreadService';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import WilmaNavLink from './WilmaReactRouter/WilmaNavLink';
import { getToThreadNavigationPath } from '../utils/utils';

interface ISearchResultPaginationProps {
    searchText: string;
    folderSelection: FolderSelection;
}

const ITEMS_PER_PAGE = 10;

const SearchResultPagination = ({searchText, folderSelection} : ISearchResultPaginationProps) => {
    const { t } = useTranslation();
    const { state: { searchResponses, activeDiscussionThread, paginationData, discussionThreadsLoading, searchPayload }, dispatch } = MessagingState();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const searchPage = searchParams.get('page');
    const location = useLocation();

    const searchTextParam = searchParams.get('searchText') ?? '';
    const folderSelectionParam = searchParams.get('folderSelection') ? Number(searchParams.get('folderSelection')) : 0;
    const selectedFolder = FolderService.getAll().find(folder => folder.id === folderSelectionParam)?.folderSelection ?? FolderService.getAll()[0].folderSelection;
    const pageParam = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

    const fetchData = async (searchText: string, folderSelection: FolderSelection, pageNumber: number) => {
        dispatch({
            type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
            payload: LoadingState.Loading
        });
        const response = await DiscussionThreadService.search({ searchText, folderSelection, pageNumber, pageSize: ITEMS_PER_PAGE });
        if (response.paginationData.currentPage !== 0) {
            dispatch({
                type: ActionType.SET_SEARCH_DISCUSSIONTHREADS,
                payload: response.discussionThreads
            });
            dispatch({
                type: ActionType.SET_PAGINATION_DATA,
                payload: { currentPage: response.paginationData.currentPage, totalPages: response.paginationData.totalPages }
            });
            dispatch({
                type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
                payload: LoadingState.Done
            });
        }
    };

    useEffect(() => {
        const fetchDataAtReload = async (searchText: string, folder: FolderSelection, page : number) => {
            await fetchData(searchText, folder, page);
        };
        if (location.search.includes('search')  && searchResponses === null) {
            const reloadSearchTextParam = searchParams.get('searchText') ?? '';
            const reloadFolderSelectionParam = searchParams.get('folderSelection') ? Number(searchParams.get('folderSelection')) : 0;
            const reloadSelectedFolder = FolderService.getAll().find(folder => folder.id === reloadFolderSelectionParam)?.folderSelection ?? FolderService.getAll()[0].folderSelection;
            const reloadPageParam = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
            fetchDataAtReload(reloadSearchTextParam, reloadSelectedFolder, reloadPageParam);
            navigate({
                pathname: `/${location.pathname}`,
                search: `?search&searchText=${reloadSearchTextParam}&folderSelection=${reloadSelectedFolder}&page=${reloadPageParam}`
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search, searchResponses, dispatch, searchTextParam, selectedFolder, pageParam]);

    const handlePageClick = (event: { selected: number; }) => {
        const newPageNumber = event.selected + 1;
        const newPath = `${location.pathname}?search&searchText=${searchPayload.searchText}&folderSelection=${searchPayload.folder.folderSelection}&page=${newPageNumber}`;
        navigate(newPath);
        fetchData(searchText, folderSelection, newPageNumber);
    };

    return (
        <>
            {discussionThreadsLoading === LoadingState.Loading && (
                <li className='center'>
                    <Spinner/>
                </li>
            )}
            {discussionThreadsLoading === LoadingState.Error && (
                <li className='center'>
                    <Icon
                        name='error'
                        size='sm'/>
                    <span className='error-text'>{t('errors.discussionThreadSearchFailed')}</span>
                </li>
            )}
            {discussionThreadsLoading === LoadingState.Done &&
                searchResponses?.map((searchResponse: ISearchResult) => {
                    const discussionThreadListItemWrapperClass = classNames('list-group-item',
                        {active: activeDiscussionThread?.id === searchResponse.discussionThread.id});
                    return (
                        <WilmaNavLink
                            key={searchResponse.discussionThread.id}
                            aria-label={activeDiscussionThread?.id === searchResponse.discussionThread.id ? t('chosenMessage') : ''}
                            className={discussionThreadListItemWrapperClass}
                            to={`${getToThreadNavigationPath(location.pathname, searchResponse.discussionThread.id.toString())}?search&searchText=${searchPayload.searchText}&folderSelection=${searchPayload.folder.folderSelection}&page=${searchPage}`}
                            tabIndex={0}
                            relative='path'
                        >
                            {<DiscussionThreadListItem discussionThread={searchResponse.discussionThread} />}
                        </WilmaNavLink>
                    );
                })
            }
            <li className='list-group-item list-group-pagination'>
                <Paginate
                    currentPage={paginationData.currentPage}
                    totalPages={paginationData.totalPages}
                    handlePageClick={handlePageClick}
                />
            </li>
        </>
    );
};

export default SearchResultPagination;