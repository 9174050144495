import { Navigate, RouterProvider, createBrowserRouter, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Toast from './components/Toast/Toast';
import { useToast } from './context/ToastContext';
import { useTranslation } from 'react-i18next';
import { ErrorPage, Spinner } from '@vismaux/react-vud';
import { useFeatureFlags } from './context/FeatureFlagContext';
import { MessagingState } from './context/Context';
import {expireSession, fetchUserInfo, refreshSession} from './services/UserInfoService';
import { ActionType } from './context/ActionTypes';
import * as amplitude from '@amplitude/analytics-browser';
import { WilmaWindow } from './@types/wilmawindow';
import dayjs from 'dayjs';
import MessageSent from './components/NewDiscussionThread/MessageSent';
import NewDiscussionThread from './components/NewDiscussionThread/NewDiscussionThread';
import Layout from './components/Layout';
import DiscussionThreadContainer from './components/DiscussionThreadContainer/DiscussionThreadContainer';
import MessageCanceled from './components/NewDiscussionThread/MessageCanceled';
import MainAreaContainer from './components/MainAreaContainer';
import EmptySelection from './components/DiscussionThreadContainer/EmptySelection';
import CustomErrorPage from './components/CustomErrorPage';

const AMPLITUDE_API_KEY = 'e0081a68257a1bf68278873cc9fbacb3';

function App() {

    const {toasts} = useToast();
    const { collectAmplitudeData } = useFeatureFlags();
    const { state: { lastActivityTimestamp }, dispatch } = MessagingState();
    const [authorizationState, setAuthorizationState] = useState<'loading' | 'unauthorized' | 'authorized'>('loading');
    const [lastRefreshTime, setLastRefreshTime] = useState(dayjs());
    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            try {
                const userInfo = await fetchUserInfo();
                
                if (collectAmplitudeData) {
                    amplitude.init(AMPLITUDE_API_KEY, userInfo?.roleGuid, {
                        serverZone: 'EU',
                        defaultTracking: {
                            sessions: true,
                            attribution: false,
                            pageViews: false,
                            fileDownloads: false,
                            formInteractions: false
                        },
                        minIdLength: 2
                    });
                }

                dispatch({
                    type: ActionType.SET_CURRENT_USER,
                    payload: userInfo
                });

                if (userInfo.roleGuid != null) {
                    setAuthorizationState('authorized');
                } else {
                    setAuthorizationState('unauthorized');
                }
            } catch (err) {
                console.error(err);
                setAuthorizationState('unauthorized');
            }
        })();
    }, [dispatch, collectAmplitudeData]);

    // Parse session activity variables from parent global Javascript space, autologout handling is in Wilma's addevent.js in global scope
    const autoLogoutDelay = parseInt((window.parent as WilmaWindow).autoLogoutDelay, 10);
    const AutoLogoutWarning = (window.parent as WilmaWindow).AutoLogoutWarning;
    // Update the auto logout prompt warning to initial status, so prompt won't appear until it "should" (see caveats with polling)
    if (AutoLogoutWarning) {
        AutoLogoutWarning.tMinusLogout = autoLogoutDelay;
    }
    // Thresholds are copied from Wilma's addevent.js
    const isTenMinuteWarning = autoLogoutDelay >= 1200;
    const isFiveMinuteWarning = autoLogoutDelay < 1200 && autoLogoutDelay >= 600;
    const isFiftyPercentWarning = autoLogoutDelay < 600;
    // Update timestamps before Wilma should show a prompt for auto logout (see addevent.js logoutWatch in Wilma's code)
    // Polling calls this a minute before the auto logout delay is displayed
    const updateWilmaSession = async () => {
        await refreshSession();
        // Refresh Wilma frontend logout warning
        AutoLogoutWarning.tMinusLogout = autoLogoutDelay;
        setLastRefreshTime(dayjs());
    };

    const slug = window.frameElement?.getAttribute('data-slug');

    useEffect(() => {
        const intervalId = setInterval(async () => {
            // This will poll session activity, trying to keep up with Wilma's session and its frontend AutoLogout Warning prompt
            // Optimally, we could query Wilma if we're still logged in without refreshing Wilma's session, but it would require separate handling from all other REST endpoints, so not doing now.
            // Problem is, if Wilma is open on multiple tabs, we can't use global frontend variable to determine whether we've been auto logged out. So we use only the refresh point to determine whether to logout or not.
            // The check with timer is to do with how Wilma's addevent.js should now clear the timer for autologout prompt: When the timer is running, it is set to a number, when it's not (prompt is shown)
            // There will be edge cases here.
            const shouldLogOut = lastRefreshTime.add(autoLogoutDelay, 'seconds').isBefore(dayjs());
            // Wilma's AutoLogoutWarning is updated to keep track of latest expdate (time of session expiration)
            const isLoggedOutFromWilma = AutoLogoutWarning.expdate != null && dayjs(AutoLogoutWarning.expdate).isBefore(dayjs());
            if (shouldLogOut && isLoggedOutFromWilma) {
                try {
                    await expireSession();
                } finally {
                    // Attempt to redirect to correct logout url, this will need refactoring when adding subroute pathing
                    const returnPath = slug ? `${slug}%2Fmessages` : 'messages';
                    window.parent.location = `/login?sessionexpired&returnpath=${returnPath}`;
                }
            }

            // If we had some activity in within a certain time after the last refresh (Wilma activity), refresh the session one minute before it would show the auto logout prompt
            if (isTenMinuteWarning && lastActivityTimestamp.subtract(9, 'minutes').isAfter(lastRefreshTime)) {
                await updateWilmaSession();
            }

            if (isFiveMinuteWarning && lastActivityTimestamp.subtract(4, 'minutes').isAfter(lastRefreshTime)) {
                await updateWilmaSession();
            }

            if (isFiftyPercentWarning && lastActivityTimestamp.subtract(autoLogoutDelay * 0.5 - 60, 'seconds').isAfter(lastRefreshTime)) {
                await updateWilmaSession();
            }
        }, 4000); // Wilma frontend polls this every 10s, we try to fit in at least 2 observations per this period

        return () => clearInterval(intervalId);
    });

    const loadingContent = () => {
        return (
            <div id="loading-container">
                <Spinner></Spinner>
            </div>
        );
    };

    const renderUnauthorizedPage = () => {
        return (
            <ErrorPage
                type="access-denied"
                title={t('errors.accessDeniedPage.title')}
                buttonCaption={t('errors.accessDeniedPage.returnButton')}
                redirectUrl='/ms/messagingfrontend'>
                {t('errors.accessDeniedPage.content')}
            </ErrorPage>
        );
    };

    const errorPageElement = (
        <CustomErrorPage
            type={'server-error'}
            title={t('errorRenderingPage')}
            buttonCaption={t('returnToMain')}
            redirectUrl={'/received/page/1'} />
    );

    const RedirectWithQuery = ({ to }: { to: string }) => {
        const location = useLocation();
        const search = location.search;
        return (
            <Navigate
                to={`${to}${search}`} 
                replace={true} />
        );
    };

    const router = createBrowserRouter([
        {
            path: '*',
            element: <Layout />,
            children: [
                { index: true, element: <Navigate
                    to='/received/page/1'
                    replace={true}/>
                },
                {
                    path: 'compose',
                    element: <RedirectWithQuery to='/received/page/1/compose' />
                },
                { path: ':folderParam/page/:pageNumber',
                    element: <MainAreaContainer />,
                    children: [
                        { index: true, element: <EmptySelection />},
                        { path: ':discussionThreadId', element: <DiscussionThreadContainer />},
                        { path: 'compose', element: <NewDiscussionThread />},
                        { path: 'messagesent', element: <MessageSent />},
                        { path: 'messagecanceled', element: <MessageCanceled/> },
                    ]
                },
                { path: '*', element: errorPageElement }
            ],
            errorElement: errorPageElement,
        },
    ], {
        basename: slug ? `${slug}/messages` : '/messages',
        window: window.parent
    });

    return (
        <div
            id="app-container"
            data-testid="app"
            className="container-fluid d-flex flex-column h-100">
            <Toast
                toastList={toasts}
            />
            {authorizationState === 'loading' && loadingContent()}
            {authorizationState === 'unauthorized' && renderUnauthorizedPage()}
            {authorizationState === 'authorized' && <RouterProvider router={router}/>}
        </div>
    );
}

export default App;
