import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IContent } from '../@types/content';
import { IDiscussionThread } from '../@types/discussionthread';
import { IMessage } from '../@types/message';
import { MessagingState } from '../context/Context';
import { getSentAtMessage, getUserDisplayName, threadHasMessagesFromMultipleAuthors } from '../utils/utils';
import classNames from 'classnames';
import { ActionType } from '../context/ActionTypes';
import * as amplitude from '@amplitude/analytics-browser';
import DiscussionThreadUserAdded from './DiscussionThreadUserAdded';
import DiscussionThreadService from '../services/DiscussionThreadService';
import { useUpdateInboxUnreadCount } from './hooks/useUpdateInboxUnreadCount';
import { Icon } from '@vismaux/react-vud';
import { IUser } from '../@types/user';
import withReplyHandling, { WithReplyHandlingProps } from './hoc/withReplyHandling';

interface Props {
    discussionThread: IDiscussionThread;
}

const DiscussionThread = ({
    replyTo,
    ...props
}: Props & WithReplyHandlingProps) => {
    const {t} = useTranslation();
    const discussionThread = props.discussionThread;
    const { state: {currentUser, unreadMessages, activeDiscussionThread}, dispatch } = MessagingState();
    const messagesStartRef = useRef<null | HTMLDivElement>(null);
    const messageNewRef = useRef<null | HTMLDivElement>(null);
    const messagesEndRef = useRef<null | HTMLDivElement>(null);
    const { updateInboxUnreadCount } = useUpdateInboxUnreadCount();

    useEffect(() => {
        if(messageNewRef.current != null ) {
            messageNewRef.current.scrollIntoView({block:'end'});
            messageNewRef.current.scrollIntoView({block:'start', behavior: 'smooth'});
        } else {
            messagesEndRef.current?.scrollIntoView();
        }
    }, [props.discussionThread.messages.length]);

    useEffect(() => {
        async function updateReadStatus() {
            const latestMessage = discussionThread.messages.slice(-1)[0];
            if (!latestMessage.isRead) {
                const response = await DiscussionThreadService.setMessageAsRead(discussionThread.id, latestMessage.id);
                if (response.status === 200) {
                    dispatch({
                        type: ActionType.SET_MESSAGE_AS_READ_SUCCESS,
                        payload: {
                            threadId: discussionThread.id,
                            messageId: latestMessage.id
                        }
                    });
                }
                // Re-fetch inbox unread count when marking thread as read, naively.
                // This might desync the count and showing received threads, when for example someone sends a new thread while the user is already
                // reading another unread thread. The read thread is marked read, but the count will be increased and no new threads would be shown.
                // Refactor this later, use for example WebSockets to communicate current thread listing statuses to frontend.
                updateInboxUnreadCount();
            }
        }
        updateReadStatus();
        amplitude.track('message_opened', {
            discussion_thread_id: props.discussionThread.id
        });
    }, [discussionThread.id, discussionThread.messages, dispatch, props.discussionThread.id, updateInboxUnreadCount]);

    const replyToThisMessage = (messageId: number, userToReplyTo: IUser) => {
        activeDiscussionThread && replyTo(messageId, userToReplyTo);
    };
    
    return (
        <div
            tabIndex={0}
            className="messageList">
            {discussionThread.messages?.map((message: IMessage, i: number) => {
                const messageDivClass = classNames({
                    message: discussionThread.messages?.length <= 1,
                    'bubble-right': discussionThread.messages?.length > 1 && message.author.roleGuid === currentUser?.roleGuid,
                    'bubble-left': discussionThread.messages?.length > 1 && message.author.roleGuid !== currentUser?.roleGuid,
                });

                const messageRef = i === 0 ? messagesStartRef : (i + 1 === discussionThread.messages.length ? messagesEndRef : null);

                return (
                    <React.Fragment
                        key={message.id}>
                        {unreadMessages.length > 0 && i !== 0 && message.id === unreadMessages[0].id &&
                        (
                            <div
                                key={'unreadMessages'}
                                className='unread-container'
                                ref={messageNewRef}>
                                <hr/>
                                <div className='unread'>{t('message.newMessage', { count: unreadMessages.length})} </div>
                            </div>
                        )
                        }
                        <div
                            className={messageDivClass}
                            ref={messageRef}>
                            {threadHasMessagesFromMultipleAuthors(discussionThread) && message?.canReplyToThisMessage && discussionThread.messages.length > 1 && (
                                <button
                                    type="button"
                                    onClick={() => replyToThisMessage(message?.id, message.author)}
                                    className='btn btn-default icon-button reply-button-right'>
                                    <Icon
                                        name='send'
                                        dynamic
                                        size='sm' />
                                </button>
                            )}
                            {message.contents.map((content: IContent, index: number) => {
                                return (
                                    <div
                                        className='container-fluid'
                                        key={index}>
                                        {discussionThread.messages.length > 1 && (
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <b>{getUserDisplayName(message.author)}</b>
                                                </div>
                                                <div className='col'>
                                                    <p className='float-right'>
                                                        {getSentAtMessage(message.sentAt)}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        <div className="row">
                                            <div className="col-md-12">
                                                <span>{content.contentString}</span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <DiscussionThreadUserAdded
                            discussionThread={discussionThread}
                            message={message}
                            index={i}/>
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default withReplyHandling(DiscussionThread);
