import React from 'react';
import FolderList from './FolderList';
import DiscussionThreadList from './DiscussionThreadList';
import SearchResultList from './SearchResultList';
import { Outlet, useSearchParams } from 'react-router-dom';

const MainAreaContainer = () => {
    const [searchParams]  = useSearchParams();
    const search = searchParams.get('search');

    return (
        <>
            <div className="col-md-2">
                <FolderList />
            </div>
            <div className="col-md-3 middle-row">
                {search !== null? (
                    <SearchResultList />
                ) : (
                    <DiscussionThreadList />
                )}
            </div>

            <div className="col-md-7">
                <div
                    id="discussion-thread-wrapper"
                    className="full-height">
                    <div className="container-fluid d-flex flex-column h-100 no-gutters">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
};

export default MainAreaContainer;