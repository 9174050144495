import React, { useEffect } from 'react';
import DiscussionThread from '../DiscussionThread';
import DiscussionThreadReply from '../DiscussionThreadReply';
import DiscussionTitle from '../DiscussionTitle';
import { useTranslation } from 'react-i18next';
import { MessagingState } from '../../context/Context';
import { Spinner } from '@vismaux/react-vud';
import { useNavigation, useParams } from 'react-router-dom';
import DiscussionThreadService from '../../services/DiscussionThreadService';
import { ActionType } from '../../context/ActionTypes';
import { LoadingState } from '../../@types/loadingstates';
import withReplyHandling from '../hoc/withReplyHandling';

const WrappedDiscussionThreadReply = withReplyHandling(DiscussionThreadReply);
import { useToast } from '../../context/ToastContext';
import FolderService from '../../services/FolderService';
import { AxiosError } from 'axios';

const DiscussionThreadContainer = () => {
    const { state: { errorCanceled, activeDiscussionThread, paginationData }, dispatch } = MessagingState();
    const { t } = useTranslation();
    const toast = useToast();
    const { state } = useNavigation();
    const { folderParam, discussionThreadId } = useParams();

    useEffect(() => {
        const fetchDiscussionThread = async () => {
            if (discussionThreadId) {
                try {
                    const discussionThreadContent = await DiscussionThreadService.getDiscussionThread(parseInt(discussionThreadId));
                    if (discussionThreadContent) {
                        dispatch({
                            type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD,
                            payload: { thread: discussionThreadContent, loadingState: LoadingState.Done } 
                        });
                    }
                } catch(error) {
                    // refetch and update discussion threads if the selected thread was cancelled
                    if (error instanceof AxiosError && error.response?.status === 404) {          
                        toast.createToast({
                            title: t('errors.cancelErrors.cancel_5'),
                            toastType: 'info'
                        });
                        const activeFolder = FolderService.getAll().find(folder => folder.path === folderParam);
                        if (activeFolder?.fetchFunction) {
                            try {
                                // TODO: WILCOM-692
                                const response = await activeFolder.fetchFunction({ pageNumber: paginationData.currentPage, pageSize: 10 }, [parseInt(discussionThreadId)]); 
                                dispatch({
                                    type: ActionType.SET_DISCUSSIONTHREADS,
                                    payload: response.discussionThreads
                                });
                                dispatch({
                                    type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD,
                                    payload: { thread: null, loadingState: LoadingState.Done } 
                                });
                                dispatch({ type: ActionType.SET_PAGINATION_DATA, payload: response.paginationData });
                            } catch (fetchError) {
                                console.error('Error fetching discussion threads:', fetchError);
                            }
                        }
                    } else {
                        console.error(error);
                    }
                }
            }
        };
        fetchDiscussionThread();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [discussionThreadId, dispatch, t]);

    return (
        <>

            {errorCanceled && (
                <div className='center'>
                    <span className='error-text'>{errorCanceled ? t('errors.discussionThreadCanceled') : t('errors.discussionThreadDataLoadingFailed')}</span>
                </div>
            )}
            {activeDiscussionThread && state === 'idle' && (
                <>
                    <DiscussionTitle
                        discussionThread={activeDiscussionThread}/>
                    <hr className='row no-gutters' />
                    <div
                        id="discussionthread-messages-container"
                        className="row wrapper justify-content-start flex-grow-1 no-gutters">
                        <DiscussionThread
                            discussionThread={activeDiscussionThread}/>
                    </div>
                    <hr className='row no-gutters' />
                    <div className='row justify-content-end no-gutters'>
                        <WrappedDiscussionThreadReply />
                    </div>
                </>
            )}
            {state === 'loading' && (
                <Spinner/>
            )}
        </>
    );
};

export default DiscussionThreadContainer;