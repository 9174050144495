import React from 'react';
import { ActionType } from '../../context/ActionTypes';
import { MessagingState } from '../../context/Context';
import { useToast } from '../../context/ToastContext';
import { IUser } from '../../@types/user';
import { useTranslation } from 'react-i18next';

export interface WithReplyHandlingProps {
  setIsReplyBoxVisible: (isVisible: boolean) => void;
  setIsReplyToAuthor: (isReplyToAuthor: boolean) => void;
  replyTo: (messageId: number, userToReplyTo: IUser) => void;
}

const withReplyHandling = <P extends WithReplyHandlingProps>(WrappedComponent: React.ComponentType<P>) => {
    const ComponentWithReplyHandling: React.FC<Omit<P, keyof WithReplyHandlingProps>> = (props) => {
        const { state: { activeDiscussionThread }, dispatch } = MessagingState();
        const toast = useToast();
        const {t} = useTranslation();

        const setIsReplyBoxVisible = (isVisible: boolean) => {
            dispatch({
                type: ActionType.SET_REPLY_BOX_VISIBILITY,
                payload: isVisible
            });
        };

        const setIsReplyToAuthor = (isReplyToAuthor: boolean) => {
            dispatch({
                type: ActionType.SET_REPLY_TO_AUTHOR,
                payload: isReplyToAuthor
            });
        };

        const replyTo = (messageId: number, userToReplyTo: IUser) => {
            if(activeDiscussionThread?.author == undefined) {
                return;
            }
            try {
                setIsReplyToAuthor(true);
                setIsReplyBoxVisible(true);
                dispatch({
                    type: ActionType.SET_REPLY_ID_AND_USER,
                    payload: {
                        messageId: messageId,
                        userToReplyTo
                    }
                });
            } catch (error) {
                toast.createToast({
                    title: t('errors.messageSendingError'),
                    toastType: 'danger'
                });
            }
        };

        return (
            <WrappedComponent
                {...(props as P)}
                setIsReplyBoxVisible={setIsReplyBoxVisible}
                setIsReplyToAuthor={setIsReplyToAuthor}
                replyTo={replyTo}
            />
        );
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    ComponentWithReplyHandling.displayName = `withReplyHandling(${wrappedComponentName})`;

    return ComponentWithReplyHandling;
};

export default withReplyHandling;