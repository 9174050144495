
import { messagingHttpClient, messagingHttpClientCached } from '../http-common';
import { IDiscussionThread, IDiscussionThreadCreatePayload, IDiscussionThreadReplyPayload, IPagedRequestPayload, IPagedResponsePayload, IDiscussionThreadResponse, IReceivedUnreadCount, IDiscussionThreadUpdatePayload } from '../@types/discussionthread';
import { AxiosResponse, CanceledError } from 'axios';
import { ISearchPayload, ISearchResponse } from '../@types/search';
import { ThreadStatus } from '../@types/threadStatus';
import { CacheAxiosResponse } from 'axios-cache-interceptor';

class DiscussionThreadService {

    private lastRequestTime: string | undefined;
    private abortController = new AbortController();
    private discussionThreadAbortController = new AbortController();

    private handleCachedResponse = async (cachedResponse: CacheAxiosResponse<IPagedResponsePayload>, queryString: string, url: string, pageSize?: number) => {
        //if response is cached then make a request with timestamp and add the data to the cache
        if (cachedResponse.cached) {
            // Add lastActivitySince to the query string
            if (this.lastRequestTime) {
                queryString += `&fromTimeStamp=${this.lastRequestTime}`;
            }
            const newThreads = await messagingHttpClient.get<IPagedResponsePayload>(url + queryString, { signal: this.abortController.signal });
            // Filter out any threads that are already in the cache
            const existingThreads = cachedResponse.data.discussionThreads.filter(
                cachedThread => !newThreads.data.discussionThreads.some(
                    newThread => newThread.id === cachedThread.id
                )
            );
            // only keep pageSize amount of threads (default 10), > pageSize threads will be moved to the next page
            cachedResponse.data.discussionThreads = newThreads.data.discussionThreads.concat(existingThreads).slice(0, pageSize ? pageSize : 10);

            // Update lastRequestTime if new threads are found
            if (newThreads.data.discussionThreads.length > 0 && newThreads.data.requestTime) {
                this.lastRequestTime = new Date(newThreads.data.requestTime).toUTCString();
            }
        }
        // Convert backend sentAt strings to JS dates for processing
        cachedResponse.data.discussionThreads = cachedResponse.data.discussionThreads.map(discussionThread =>
            ({
                ...discussionThread, messages: discussionThread.messages.map(message => ({ ...message, sentAt: new Date(message.sentAt) }))
            })
        );
        return cachedResponse;
    };

    getReceived = async (payload? : IPagedRequestPayload, canceledThreadIDs?: number[] ): Promise<IPagedResponsePayload> => {
        this.abortController.abort();
        this.abortController = new AbortController();
        const url = '/ms/messaging/discussionthread/received';
        try {
            const queryString = payload ? `?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}` : '';
            let response: CacheAxiosResponse<IPagedResponsePayload> | AxiosResponse<IPagedResponsePayload>;
            let updatedData: CacheAxiosResponse<IPagedResponsePayload> | AxiosResponse<IPagedResponsePayload>;
            // If the first page is requested, use the cached response
            if (payload?.pageNumber == 1) {
                response = await messagingHttpClientCached.get<IPagedResponsePayload>(url + queryString, { signal: this.abortController.signal });
                updatedData = await this.handleCachedResponse(response as CacheAxiosResponse<IPagedResponsePayload> , queryString, url, payload?.pageSize);
                // filter out canceled threads TODO:WILCOM-692
                updatedData.data.discussionThreads = updatedData.data.discussionThreads.filter(thread => !canceledThreadIDs?.includes(thread.id));
            } else {
                response = await messagingHttpClient.get<IPagedResponsePayload>(url + queryString, { signal: this.abortController.signal });
                updatedData = response;
            }

            // Update lastRequestTime after the first call
            if (!this.lastRequestTime && response.data.requestTime) {
                this.lastRequestTime = new Date(response.data.requestTime).toUTCString();
            }

            return {
                discussionThreads: updatedData.data.discussionThreads,
                paginationData: updatedData.data.paginationData,
            };

        } catch (e) {
            if (e instanceof CanceledError) {
                return {
                    discussionThreads: [],
                    paginationData: {
                        totalCount: 0,
                        pageSize: 0,
                        currentPage: 0,
                        totalPages: 0
                    }              
                };
            } else {
                throw e;
            }
        }
    };

    InboxUnreadCount() {
        return messagingHttpClient.get<IReceivedUnreadCount>('/ms/messaging/discussionthread/unread');
    }

    create(data: IDiscussionThreadCreatePayload) {
        return messagingHttpClient.post<IDiscussionThreadResponse>('/ms/messaging/discussionthread', data);
    }

    createReplyToSender(data: IDiscussionThreadReplyPayload) {
        return messagingHttpClient.post<IDiscussionThread>('/ms/messaging/discussionthread/replytosender', data);
    }

    reply(data: string, id: number) {
        return messagingHttpClient.post<IDiscussionThread>('/ms/messaging/discussionthread/' + id, { reply: data });
    }

    getSent = async (payload? : IPagedRequestPayload, canceledThreadIDs?: number[]): Promise<IPagedResponsePayload> => {
        this.abortController.abort();
        this.abortController = new AbortController();
        const url = '/ms/messaging/discussionthread/sent';
        try {
            const queryString = payload ? `?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}` : '';
            let response: CacheAxiosResponse<IPagedResponsePayload> | AxiosResponse<IPagedResponsePayload>;
            let updatedData: CacheAxiosResponse<IPagedResponsePayload> | AxiosResponse<IPagedResponsePayload>;
            // If the first page is requested, use the cached response
            if (payload?.pageNumber == 1) {
                response = await messagingHttpClientCached.get<IPagedResponsePayload>(url + queryString, { signal: this.abortController.signal });
                updatedData = await this.handleCachedResponse(response as CacheAxiosResponse<IPagedResponsePayload> , queryString, url);
                // filter out canceled threads TODO:WILCOM-692
                updatedData.data.discussionThreads = updatedData.data.discussionThreads.filter(thread => !canceledThreadIDs?.includes(thread.id));
            } else {
                response = await messagingHttpClient.get<IPagedResponsePayload>(url + queryString, { signal: this.abortController.signal });
                updatedData = response;
            }

            return {
                discussionThreads: updatedData.data.discussionThreads,
                paginationData: updatedData.data?.paginationData
            };

        } catch (e) {
            if (e instanceof CanceledError) {
                return {
                    discussionThreads: [],
                    paginationData: {
                        totalCount: 0,
                        pageSize: 0,
                        currentPage: 0,
                        totalPages: 0
                    }
                };
            } else {
                throw e;
            }
        }
    };

    search = async (searchPayload: ISearchPayload) : Promise<ISearchResponse> => {
        this.abortController.abort();
        this.abortController = new AbortController();
        try {
            const response =  await messagingHttpClient.post<ISearchResponse>('/ms/messaging/Search', {...searchPayload }, {signal: this.abortController.signal});
            const data = response.data;

            const results = {
                discussionThreads: data.discussionThreads,
                paginationData: data.paginationData
            };

            return results;
        } catch (e) {
            if (e instanceof CanceledError) {
                return {
                    discussionThreads: [],
                    paginationData: {
                        totalCount: 0,
                        pageSize: 0,
                        currentPage: 0,
                        totalPages: 0
                    }
                };
            } else {
                throw e;
            }
        }
    };

    updateRecipientList = async (recipientGuids: string[], threadId: number) => {
        return await this.updateThread({ recipientGUIDs: recipientGuids }, threadId);
    };

    updateThread = async (payload: IDiscussionThreadUpdatePayload, threadId: number) => {
        return messagingHttpClient.patch<IDiscussionThread>(`/ms/messaging/discussionthread/${threadId}`, payload);
    };

    cancelThread = async (threadId: number) => {
        return await this.updateThread({ threadStatus: ThreadStatus.cancelled }, threadId);
    };

    getDiscussionThread = async (threadId: number) => {
        this.discussionThreadAbortController.abort();
        this.discussionThreadAbortController = new AbortController();
        try {
            const response = await messagingHttpClient.get<IDiscussionThread>(`/ms/messaging/discussionthread/${threadId}`, { signal: this.discussionThreadAbortController.signal});
            return response.data;
        } catch (e) {
            if (e instanceof CanceledError) {
                return null;
            } else {
                throw e;
            }
        }
    };

    setMessageAsRead = (threadId: number, messageId: number) => {
        return messagingHttpClient.post<void>(`/ms/messaging/discussionthread/${threadId}/markread`, { messageId });
    };
}

export default new DiscussionThreadService();
