import { IDiscussionThread, IDiscussionThreadSelectPayload } from '../@types/discussionthread';
import { IFolder } from '../@types/folder';
import { IUser } from '../@types/user';
import { IIndexRecords, IModalRecipient, IPrimusSearches, IRecipientSelectionData } from '../@types/recipients';
import { ISearchResult } from '../@types/search';
import { ActionType } from './ActionTypes';
import { IMessagingContextState, initialState } from './Context';
import { getThreadUnreadMessages } from '../utils/utils';
import { StringOption } from '../components/WilmaAsyncSelect/WilmaAsyncSelect';
import { Loading, LoadingState } from '../@types/loadingstates';
import dayjs from 'dayjs';

export type MessagingContextActions =
    | { type: ActionType.SET_DISCUSSIONTHREADS, payload: IDiscussionThread[]; }
    | { type: ActionType.SET_UNREAD_INBOX_COUNT, payload: { count: number, loadingState: LoadingState } }
    | { type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD, payload: { thread: IDiscussionThread | null; loadingState: LoadingState; errorCanceled?: boolean, canceledThreadId?: number } }
    | { type: ActionType.SET_LAST_MESSAGE_SENT, payload: { threadId: number | null, canCancelThread: boolean }; }
    | { type: ActionType.SET_MESSAGE_AS_READ_SUCCESS, payload: { threadId: number, messageId: number }; }
    | { type: ActionType.SET_CURRENT_USER, payload: IUser | undefined }
    | { type: ActionType.SET_SEARCH_DISCUSSIONTHREADS, payload: ISearchResult[]; }
    | { type: ActionType.SET_SEARCH_FOCUS, payload: boolean; }
    | { type: ActionType.UPDATE_DISCUSSION_THREAD, payload: IDiscussionThread }
    | { type: ActionType.SET_SEARCH_PAYLOAD, payload: { searchText: string, folder: IFolder; }; }
    | { type: ActionType.SET_SELECTED_RECIPIENTS, payload: { options: StringOption[] }; }
    | { type: ActionType.SET_MODAL_RECIPIENTS, payload: { modalRecipientOptions: IModalRecipient[] }; }
    | { type: ActionType.SET_MESSAGE, payload: IDiscussionThreadSelectPayload; }
    | { type: ActionType.SET_RECIPIENTLIST_VIEW, payload: IRecipientSelectionData; }
    | { type: ActionType.SET_SELECTED_SCHOOL, payload: string; }
    | { type: ActionType.SET_ACTIVE_BUTTON_ID, payload: string; }
    | { type :ActionType.MODAL_IS_RECIPIENT_IN_VIEW, payload: { value: string, inView: boolean }}
    | { type: ActionType.SET_GENERAL_RECIPIENT_DATA, payload: IIndexRecords & Loading; }
    | { type: ActionType.SET_PAGINATION_DATA, payload: { currentPage: number, totalPages: number}; }
    | { type: ActionType.SET_DISCUSSIONTHREADS_LOADING, payload: LoadingState }
    | { type: ActionType.SET_PRIMUS_SEARCHES, payload: IPrimusSearches; }
    | { type: ActionType.SET_REPLY_BOX_VISIBILITY, payload: boolean; }
    | { type: ActionType.SET_REPLY_TO_AUTHOR, payload: boolean; }
    | { type: ActionType.SET_REPLY_ID_AND_USER, payload: { messageId: number, userToReplyTo: IUser | null }; };

export const messagingReducer = (state: IMessagingContextState, action: MessagingContextActions): IMessagingContextState => {

    state.lastActivityTimestamp = dayjs();
    const uiClearedState = clearUiState(state);

    switch (action.type) {

        case ActionType.SET_DISCUSSIONTHREADS:
            return {
                ...state, discussionThreads: action.payload
            };

        case ActionType.SET_UNREAD_INBOX_COUNT:
            return {...state, unreadInboxCount: action.payload.count, unreadInboxCountLoading: action.payload.loadingState };

        case ActionType.SET_ACTIVE_DISCUSSIONTHREAD:
            if (action.payload.thread && !action.payload.errorCanceled) {
                return {
                    ...uiClearedState, activeDiscussionThread: action.payload.thread, threadLoadingState: action.payload.loadingState, unreadMessages: getThreadUnreadMessages(action.payload.thread),
                    discussionThreads: mutateAllThreadsWithThread(uiClearedState.discussionThreads, action.payload.thread), errorCanceled: initialState.errorCanceled
                };
            }
            if (action.payload.errorCanceled) {
                return {
                    ...uiClearedState, activeDiscussionThread: action.payload.thread, threadLoadingState: action.payload.loadingState, unreadMessages: [],
                    errorCanceled: action.payload.errorCanceled, discussionThreads: uiClearedState.discussionThreads.filter(dt => dt.id !== action.payload.canceledThreadId)
                };
            }
            return {
                ...uiClearedState, activeDiscussionThread: action.payload.thread, threadLoadingState: action.payload.loadingState, unreadMessages: [],
                errorCanceled: initialState.errorCanceled
            };

        case ActionType.SET_MESSAGE_AS_READ_SUCCESS:
            return {
                ...uiClearedState, discussionThreads: markThreadMessagesAsReadForAllThreads(uiClearedState.discussionThreads, action.payload.threadId, action.payload.messageId),
                activeDiscussionThread: uiClearedState.activeDiscussionThread ?
                    markSingleThreadMessagesAsRead(uiClearedState.activeDiscussionThread, action.payload.threadId, action.payload.messageId)
                    :
                    null
            };

        case ActionType.SET_MESSAGE:
            return {
                ...state, message: action.payload
            };

        case ActionType.SET_SELECTED_RECIPIENTS:
            return {
                ...state, selectedRecipients: action.payload
            };

        case ActionType.SET_MODAL_RECIPIENTS:
        {
            const existingModalHiddenRecipientLabels = state.modalHiddenRecipientLabels.filter(value => action.payload.modalRecipientOptions.some(option => option.value === value));
            return {
                ...state, modalRecipients: action.payload, modalHiddenRecipientLabels: existingModalHiddenRecipientLabels
            };
        }

        case ActionType.SET_RECIPIENTLIST_VIEW:
            return {
                ...state, recipientListData: action.payload
            };

        case ActionType.SET_SELECTED_SCHOOL:
            return {
                ...state, selectedSchoolId: action.payload
            };

        case ActionType.SET_LAST_MESSAGE_SENT:
            return {
                ...state, lastSentMessageData: action.payload
            };

        case ActionType.SET_CURRENT_USER:
            return {
                ...uiClearedState, currentUser: action.payload
            };

        case ActionType.SET_SEARCH_DISCUSSIONTHREADS:
            return {
                ...uiClearedState, searchResponses: action.payload, activeDiscussionThread: null
            };

        case ActionType.SET_SEARCH_FOCUS:
            return {
                ...uiClearedState, searchFocus: action.payload
            };

        case ActionType.UPDATE_DISCUSSION_THREAD:
            return {
                ...uiClearedState,
                discussionThreads: uiClearedState.discussionThreads.map(dt => dt.id === action.payload.id ? action.payload : dt),
                // If active discussion thread was selected, need to update it as well since it's supplied separately to views
                activeDiscussionThread: uiClearedState.activeDiscussionThread ?
                    uiClearedState.activeDiscussionThread.id === action.payload.id ?
                        action.payload : uiClearedState.activeDiscussionThread : null
            };

        case ActionType.SET_SEARCH_PAYLOAD:
            return {
                ...uiClearedState, searchPayload: action.payload
            };

        case ActionType.SET_ACTIVE_BUTTON_ID:
            return {
                ...state, activeButtonId: action.payload
            };

        case ActionType.SET_GENERAL_RECIPIENT_DATA:
            return {
                ...state, generalRecipientData: action.payload
            };

        case ActionType.MODAL_IS_RECIPIENT_IN_VIEW:
        {
            let modalHiddenRecipientLabels = [...state.modalHiddenRecipientLabels];
            if (action.payload.inView) {
                modalHiddenRecipientLabels = modalHiddenRecipientLabels.filter(value => value !== action.payload.value);
            } else {
                if (!modalHiddenRecipientLabels.includes(action.payload.value)) {
                    modalHiddenRecipientLabels = modalHiddenRecipientLabels.concat([action.payload.value]);
                }
            }
            return {
                ...state, modalHiddenRecipientLabels
            };
        }
        case ActionType.SET_PAGINATION_DATA:
            return {
                ...state, paginationData: action.payload
            };
        case ActionType.SET_DISCUSSIONTHREADS_LOADING:
            return {
                ...state, discussionThreadsLoading: action.payload
            };
        case ActionType.SET_PRIMUS_SEARCHES:
            return {
                ...state, primusSearches: action.payload
            };
        case ActionType.SET_REPLY_BOX_VISIBILITY:
            return {
                ...state, isReplyBoxVisible: action.payload
            };
        case ActionType.SET_REPLY_TO_AUTHOR:
            return {
                ...state, isReplyToAuthor: action.payload
            };
        case ActionType.SET_REPLY_ID_AND_USER:
            return {
                ...state, replyInfo: { messageId: action.payload.messageId, userToReplyTo: action.payload.userToReplyTo }
            };
        default:
            return uiClearedState;
    }
};

const markThreadMessagesAsReadForAllThreads = (discussionThreads: IDiscussionThread[], threadId: number, messageId: number ) => {
    return [...discussionThreads.map(dt => (markSingleThreadMessagesAsRead(dt, threadId, messageId)))];
};

const mutateAllThreadsWithThread = (discussionThreads: IDiscussionThread[], mutateDiscussionThread: Partial<IDiscussionThread> & { id: number }) => {
    return [...discussionThreads.map(dt => dt.id === mutateDiscussionThread.id ? { ...dt, ...mutateDiscussionThread } : dt)];
};

const markSingleThreadMessagesAsRead = (discussionThread: IDiscussionThread, threadId: number, messageId: number) => {
    if (discussionThread.id === threadId) {
        const lastReadIndex = discussionThread.messages.findIndex(message => message.id === messageId);
        const messages = discussionThread.messages.map((message, index) => index <= lastReadIndex ? { ...message, isRead: true} : message);
        return { ...discussionThread, messages };
    }
    return discussionThread;
};

const clearUiState = (state: IMessagingContextState) => {
    return {
        ...state,
        message: {
            title: '',
            recipients: [],
            message: '',
            seeNames: false,
            seeResponses: false
        },
        selectedRecipients: { options: [] },
        threadCanceledView: false
    };
};
